import React, { useEffect, useState } from 'react';
import SEO from '../components/seo';
import Unsubscribed from '../containers/Unsubscribed';
import { ResetCSS } from 'common/src/assets/css/style';
import { useAlert } from 'react-alert';
import axios from 'axios';
import queryString from 'query-string';
import displayErrors from '../utils';

const UnsubscribedPage = () => {
  const alert = useAlert();
  const [titleText, setTitleText] = useState('Processing...');
  const [captionText, setCaptionText] = useState('');

  useEffect(() => {
    var email, token;
    if (window) {
      email = queryString.parse(window.location.search).email;
      token = queryString.parse(window.location.search).token;
    }

    axios
      .post(`${process.env.GATSBY_API_URL}/unsubscribeEmail/`, { email, token })
      .then((res) => {
        setTitleText('Unsubscribed!');
        setCaptionText('You will no longer receive emails from us.');
        alert.show(
          <div style={{ textTransform: 'initial' }}>{res.data.message}</div>,
          {
            type: 'success',
          }
        );
      })
      .catch((err) => {
        setTitleText('Unable to Unsubscribe!');
        displayErrors(err, alert);
      });
  }, []);

  return (
    <>
      <SEO title="Unsubscribed" />
      <ResetCSS />
      <Unsubscribed
        titleText={titleText}
        captionText={captionText}
      ></Unsubscribed>
    </>
  );
};

export default UnsubscribedPage;
